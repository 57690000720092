import Network from 'lib/api/network';
import Random from 'lib/random';
import _ from 'lodash';

export default {
  namespaced: true,

  state() {
    return {
      currentUserTimeZone: null,
      timeZones: null,
      startDate: null,
      tags: [],
      flowType: null,
      roles: [],
      pathTag: null,
      defaultSession: {
        startDate: null,
        tagIds: [],
        memberships: [],
        timeZone: { label: 'Import from Slack', value: 'sync' },
      },
      sessions: [],
    };
  },

  getters: {
    involvedTags(state) {
      const tagIds = _.uniq(_.flatMap(state.sessions.map(session => session.tagIds)));
      return state.tags.filter(tag => tagIds.includes(tag.id));
    },

    involvedRoles(state, getters) {
      const roleIds = _.uniq(_.flatMap(getters.involvedTags.map(tag => tag.roleIds)));
      return state.roles.filter(role => roleIds.includes(role.id));
    },

    tagsForSession: (state) => (session) => (session || state.defaultSession).tagIds.map(id => state.tags.find(t => t.id === id)),

    findRole: (state) => ({ id, purpose }) => state.roles.find((role) => {
      if (id) return role.id === id;
      return role.purpose === purpose;
    }),

    importTimeZoneOption() {
      return { label: 'Import from Slack', value: 'sync' };
    },
  },

  mutations: {
    updateRoleIdsForTag(state, { tagId, roleIds }) {
      state.tags.find(tag => tag.id === tagId).roleIds = roleIds;
    },

    addSession(state, { startDate }) {
      const blankSessionFromDefault = _.cloneDeep(state.defaultSession);
      blankSessionFromDefault.id = Random.uuid();
      if (startDate) blankSessionFromDefault.startDate = startDate;
      state.sessions.push(blankSessionFromDefault);
    },

    deleteSession(state, { session }) {
      const sessionIdx = state.sessions.findIndex(s => s.id === session.id);
      state.sessions.splice(sessionIdx, 1);
    },

    updateSession(state, { id, key, value }) {
      if (id) {
        // Deep clone and replace a session from the sessions array
        const sessionIdx = state.sessions.findIndex(s => s.id === id);
        const newSession = _.cloneDeep(state.sessions[sessionIdx]);
        newSession[key] = value;
        state.sessions[sessionIdx] = newSession;
      } else {
        // If no id is provided, that means we deep clone and replace the defaultSession
        const newSession = _.cloneDeep(state.defaultSession);
        newSession[key] = value;
        state.defaultSession = newSession;
      }
    },

    updateMembership(state, {
     sessionId, roleId, key, value, preserveExistingMembership,
    }) {
      // Find the membership for this session and this role. (If one exists and we want to preserve existing memberships, just return out)
      const session = sessionId ? state.sessions.find(s => s.id === sessionId) : state.defaultSession;
      let membership = session.memberships.find(m => m.role.id === roleId);
      if (membership && preserveExistingMembership) return;
      // If there is no membership for this session and this role, create one
      if (!membership) {
        session.memberships.push({ role: { ...state.roles.find(r => r.id === roleId) } });
        membership = session.memberships.find(m => m.role.id === roleId);
      }
      // Set the value. If setting `user` or `role_selection`, nullify the other
      membership[key] = value;
      if (key === 'user') membership.role_selection = null;
      if (key === 'role_selection') membership.user = null;
    },
  },

  actions: {
    importCsv() {
      // TODO: Obviously this needs to do something
    },

    applyUniversalTags({ state, commit }, { idsToAdd, idsToRemove }) {
      // We want to apply the changes to both the default and to every session already in rows
      [state.defaultSession, ...state.sessions].forEach((session) => {
        const newIds = [];
        session.tagIds.forEach((id) => {
          if (!idsToRemove.includes(id)) newIds.push(id);
        });
        idsToAdd.forEach((id) => {
          if (!newIds.includes(id)) newIds.push(id);
        });
        commit('updateSession', { id: session.id, key: 'tagIds', value: newIds });
      });
    },

    applyUniversalRoleSelection({ state, commit }, { roleId, user, roleSelection, preserveExistingMembership }) {
      const updateArgs = user ? { roleId, key: 'user', value: user } : { roleId, key: 'role_selection', value: roleSelection };
      // First, update the defaultSession with the new selections
      commit('updateMembership', updateArgs);
      // Next apply the role selection to all sessions, overriding or not based on the `override` bool
      state.sessions.forEach((session) => {
        commit('updateMembership', {
          ...updateArgs,
          sessionId: session.id,
          preserveExistingMembership,
        });
      });
    },

    fetchRolesForTag({ state, commit }, { tagId }) {
      // Too expensive to load every involved role for every tag, so load em as we need em
      // First check to see if we've loaded them already, and if we haven't,
      // load them via fetch_roles_as_json and put them in the tag in state.
      const tag = state.tags.find(t => t.id === tagId);
      if (!tag.roleIds) {
        return new Promise((resolve, _reject) => {
          Network.get(`/${tag.route_flow_type}/tags/${tag.id}/fetch_roles_as_json`, {
              success: ({ roleIds }) => {
                commit('updateRoleIdsForTag', { tagId: tag.id, roleIds });
                resolve(true);
              },
            });
        });
      }
    },
  },
};
